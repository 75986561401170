<template>
  <div class="form-group">
    <!-- eslint-disable vue/no-v-html -->
    <label v-if="title" :for="id" v-html="title" />
    <select
      v-if="!search"
      :id="id"
      ref="mySelect"
      v-model="selectedValue"
      :class="{ 'form-control': true, customselectclass: customclass != '' }"
      :aria-label="arialabel"
      :required="required"
      :value="selected"
      :disabled="selectedDisabled"
      @input="handleInput"
      @change="handleChange"
      @click="onClick"
    >
      <option
        v-for="option in options"
        :key="option.value"
        :disabled="option.disabled"
        :value="option.value"
        :selected="option.value == selected"
      >{{ option.text }}</option>
    </select>
    <el-select v-else v-model="selected" filterable placeholder="Select">
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.text"
        :value="item.value"
      />
    </el-select>
  </div>
</template>

<script>
import { Select, Option } from 'element-ui'

export default {
  name: 'DropdownInput',
  components: {
    ElSelect: Select,
    ElOption: Option,
  },
  props: {
    id: {
      type: String,
      required: false,
      default: '',
    },
    value: {
      type: Number,
      required: false,
      default: null,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectedFirst: {
      type: Boolean,
      required: false,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    options: {
      type: Array,
      required: true,
    },
    customclass: {
      type: String,
      required: false,
      default: '',
    },
    selectedDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    search: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Buscar',
    },
    arialabel: {
      type: String,
      required: false,
      default: '',
    },
    optionsDisabled: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      selected: this.value,
    }
  },
  computed: {
    HasValue() {
      return this.options.some(x => x.value === this.selected)
    },
    selectedValue: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
        this.value = newValue
      },
    },
  },
  watch: {
    options() {
      this.selecteDefaultItem()
    },
    selectedDisabled() {
      this.selecteDefaultItem()
    },
    value(newVal) {
      this.selected = newVal
    },
  },
  mounted() {
    this.selecteDefaultItem()
  },
  methods: {
    onClick() {
      this.$emit('on-click')
    },
    selecteDefaultItem() {
      if (!this.options || (this.options && !this.options.length)) {
        this.selected = null
      } else if (!this.selected || !this.HasValue) {
        this.selected = this.options[0].value
      }

      this.$emit('input', this.selected)
    },
    handleInput(e) {
      this.selected = e.target.value
    },
    handleChange(e) {
      this.$emit('change', e.target.value)
    },
    setSelectedValue(newValue) {
      this.selected = newValue
    },
  },
}
</script>

<style>
select option:disabled {
  color: #cccccc;
}
</style>
